// ------------------------------------
// Action constants
// Name with model/element first, action second
// e.g. FOO_HIDE, BAR_EDIT
// ------------------------------------

export const REDUX_FORM_CHANGE = '@@redux-form/CHANGE'

export const LOCATION_CHANGE = '@@router/LOCATION_CHANGE'

export const FORM_ERROR = 'FORM_ERROR'

export const FLOW_SUCCESS = 'FLOW_SUCCESS'
export const FLOW_RESET = 'FLOW_RESET'
export const FLOW_RESET_WITH_DATA = 'FLOW_RESET_WITH_DATA'

export const FLOW_UPDATE_DATA = 'FLOW_UPDATE_DATA'
export const FLOW_MERGE_DATA = 'FLOW_MERGE_DATA'

export const CHANGE_CREDENTIALS_REGISTER_STEPS = 'CHANGE_CREDENTIALS_REGISTER_STEPS'
export const CHANGE_CREDENTIALS_NEXT_STEP = 'CHANGE_CREDENTIALS_NEXT_STEP'
// v21 reset password flow
export const AUTH_TOKEN_FAILURE = 'AUTH_TOKEN_FAILURE'
export const DISPLAY_LOADER = 'DISPLAY_LOADER'
export const VERIFY_AUTH_TOKEN = 'VERIFY_AUTH_TOKEN'

export const SESSION_ID_UPDATE = 'SESSION_ID_UPDATE'
export const SESSION_LOCAL_STORAGE_ID_UPDATE = 'SESSION_LOCAL_STORAGE_ID_UPDATE'
export const SESSION_META_UPDATE = 'SESSION_META_UPDATE'

export const USER_DETAILS_UPDATE = 'USER_DETAILS_UPDATE'

export const SORTCODE_VALIDATE = 'SORTCODE_VALIDATE'
export const ACCOUNT_INIT = 'ACCOUNT_INIT'
export const ACCOUNT_DETAILS_FETCH = 'ACCOUNT_DETAILS_FETCH'
export const ACCOUNT_DETAILS_UPDATE = 'ACCOUNT_DETAILS_UPDATE'
export const ACCOUNT_DETAILS_FETCH_FAIL = 'ACCOUNT_DETAILS_FETCH_FAIL'
export const ACCOUNTS_FETCH = 'ACCOUNTS_FETCH'
export const ACCOUNT_CUSTOMER_ADDRESS_UPDATE = 'ACCOUNT_CUSTOMER_ADDRESS_UPDATE'
export const ACCOUNTS_FETCH_FAIL = 'ACCOUNTS_FETCH_FAIL'
export const ACCOUNTS_UPDATE = 'ACCOUNTS_UPDATE'
export const ACCOUNTS_REALTOMASKED_UPDATE = 'ACCOUNTS_REALTOMASKED_UPDATE'
export const ACCOUNTS_TRANSFER_MAPPING_KNOWN = 'ACCOUNTS_TRANSFER_MAPPING_KNOWN'
export const ACCOUNTS_MAPPING_ADD = 'ACCOUNTS_MAPPING_ADD'
export const ACCOUNTS_ORDER_CHANGE = 'ACCOUNTS_ORDER_CHANGE'
export const ACCOUNTS_ORDER_SUBMIT = 'ACCOUNTS_ORDER_SUBMIT'
export const ACCOUNTS_ORDER_SUBMIT_FINISHED = 'ACCOUNTS_ORDER_SUBMIT_FINISHED'

export const TRANSACTIONS_FETCH_MORE = 'TRANSACTIONS_FETCH_MORE'
export const TRANSACTIONS_ADD_MORE = 'TRANSACTIONS_ADD_MORE'
export const TRANSACTIONS_FETCH_FAIL = 'TRANSACTIONS_FETCH_FAIL'
export const TRANSACTIONS_SWITCH_DEBIT_CREDIT = 'TRANSACTIONS_SWITCH_DEBIT_CREDIT'
export const TRANSACTIONS_SEARCH = 'TRANSACTIONS_SEARCH'
export const TRANSACTIONS_FILTER_AMOUNT_MIN_MAX = 'TRANSACTIONS_FILTER_AMOUNT_MIN_MAX'
export const U_TRANSACTIONS_FILTER_DATES = 'U_TRANSACTIONS_FILTER_DATES'
export const R_TRANSACTIONS_FILTER_DATES = 'R_TRANSACTIONS_FILTER_DATES'
export const TRANSACTIONS_FETCH_STOP = 'TRANSACTIONS_FETCH_STOP'
export const TRANSACTIONS_FETCH_START = 'TRANSACTIONS_FETCH_START'
export const TRANSACTIONS_TEMP_ITEMS_POPULATE = 'TRANSACTIONS_TEMP_ITEMS_POPULATE'
export const TRANSACTIONS_TEMP_ITEMS_CLEAR = 'TRANSACTIONS_TEMP_ITEMS_CLEAR'
export const TRANSACTIONS_CLEAR_DATES = 'TRANSACTIONS_CLEAR_DATES'
export const TRANSACTIONS_CLEAR_FILTERS = 'TRANSACTIONS_CLEAR_FILTERS'
export const TRANSACTIONS_FILTER_TAGS = 'TRANSACTIONS_FILTER_TAGS'
export const TRANSACTIONS_GOT_ALL = 'TRANSACTIONS_GOT_ALL'
export const TRANSACTIONS_FETCH_LATEST = 'TRANSACTIONS_FETCH_LATEST'
export const TRANSACTIONS_MERGE_LATEST = 'TRANSACTIONS_MERGE_LATEST'
export const TRANSACTIONS_MERGE_MORE = 'TRANSACTIONS_MERGE_MORE'
export const TRANSACTIONS_DOWNLOAD = 'TRANSACTIONS_DOWNLOAD'
export const TRANSACTIONS_KEYWORD_SEARCH_FETCH_MORE = 'TRANSACTIONS_KEYWORD_SEARCH_FETCH_MORE'
export const TRANSACTIONS_KEYWORD_SEARCH_MERGE_MORE = 'TRANSACTIONS_KEYWORD_SEARCH_MERGE_MORE'
// ADDED for date filter pagination
export const TRANSACTIONS_FETCH_MORE_FILTERS = 'TRANSACTIONS_FETCH_MORE_FILTERS'
export const UPDATE_FILTER_SHOW_MORE_COUNT = 'UPDATE_FILTER_SHOW_MORE_COUNT'
export const UPDATE_FILTER_SHOW_MORE_COUNT_ZERO = 'UPDATE_FILTER_SHOW_MORE_COUNT_ZERO'
export const TEMP_ITEM_MERGE = 'TEMP_ITEM_MERGE'
export const TRANSACTIONS_TEMP_ITEMS_POPULATE_DATE = 'TRANSACTIONS_TEMP_ITEMS_POPULATE_DATE'
// END ADD NEW

export const R_STATEMENTS_FETCH_REQUEST = 'R_STATEMENTS_FETCH_REQUEST'
export const U_STATEMENTS_FETCH_REQUEST = 'U_STATEMENTS_FETCH_REQUEST'
export const STATEMENTS_FETCH_SUCCESS = 'STATEMENTS_FETCH_SUCCESS'
export const STATEMENTS_FETCH_FAIL = 'STATEMENTS_FETCH_FAIL'
export const STATEMENTS_FETCH_STOP = 'STATEMENTS_FETCH_STOP'
export const STATEMENT_DOWNLOAD_REQUEST = 'STATEMENT_DOWNLOAD_REQUEST'
export const STATEMENT_DOWNLOAD_SUCCESS = 'STATEMENT_DOWNLOAD_SUCCESS'
export const STATEMENT_DOWNLOAD_FAIL = 'STATEMENT_DOWNLOAD_FAIL'
export const STATEMENTS_FETCH_ALL_START = 'STATEMENTS_FETCH_ALL_START'
export const STATEMENTS_FETCH_ALL_STOP = 'STATEMENTS_FETCH_ALL_STOP'

export const SUPERDATEPICKER_INIT = 'SUPERDATEPICKER_INIT'
export const SUPERDATEPICKER_CANCEL = 'SUPERDATEPICKER_CANCEL'
export const SUPERDATEPICKER_CHANGE = 'SUPERDATEPICKER_CHANGE'
export const SUPERDATEPICKER_SHORTCUT_CLICK = 'SUPERDATEPICKER_SHORTCUT_CLICK'
export const SUPERDATEPICKER_INPUT_CLICK = 'SUPERDATEPICKER_INPUT_CLICK'
export const SUPERDATEPICKER_INPUT_CHANGE = 'SUPERDATEPICKER_INPUT_CHANGE'
export const SUPERDATEPICKER_INPUT_BLUR = 'SUPERDATEPICKER_INPUT_BLUR'
export const SUPERDATEPICKER_INPUT_KEYPRESS = 'SUPERDATEPICKER_INPUT_KEYPRESS'
export const SUPERDATEPICKER_NATIVE_INPUT_CHANGE = 'SUPERDATEPICKER_NATIVE_INPUT_CHANGE'
export const SUPERDATEPICKER_UPDATE_LAST_SUBMITTED = 'SUPERDATEPICKER_UPDATE_LAST_SUBMITTED'

export const PANEL_TOGGLE = 'PANEL_TOGGLE'
export const PANEL_HIDE = 'PANEL_HIDE'
export const PANEL_SHOW = 'PANEL_SHOW'

export const TOOLTIP_TOGGLE = 'TOOLTIP_TOGGLE'
export const TOOLTIP_HIDE = 'TOOLTIP_HIDE'
export const TOOLTIP_SHOW = 'TOOLTIP_SHOW'

export const MOB_MENU_TOGGLE = 'MOB_MENU_TOGGLE'
export const MOB_MENU_HIDE = 'MOB_MENU_HIDE'
export const MOB_MENU_SHOW = 'MOB_MENU_SHOW'

export const MOB_SUB_MENU_TOGGLE = 'MOB_SUB_MENU_TOGGLE'

export const MEGA_MENU_SHOW = 'MEGA_MENU_SHOW'
export const MEGA_MENU_HIDE = 'MEGA_MENU_HIDE'

export const MODAL_SHOW = 'MODAL_SHOW'
export const MODAL_HIDE = 'MODAL_HIDE'
export const MODAL_ERROR_SHOW = 'MODAL_ERROR_SHOW'

export const SET_MISC = 'SET_MISC'

export const ENTITIES_CHANGE_ACTIVE = 'ENTITIES_CHANGE_ACTIVE'
export const ENTITIES_FETCH_REQUEST = 'ENTITIES_FETCH_REQUEST'
export const ENTITIES_FETCH_FAIL = 'ENTITIES_FETCH_FAIL'
export const ENTITIES_UPDATE_ITEMS = 'ENTITIES_UPDATE_ITEMS'
export const SHOW_PAYEE_MESSAGE = 'SHOW_PAYEE_MESSAGE'
export const SET_SHOW_PAYEE_MESSAGE_FALSE = 'SET_SHOW_PAYEE_MESSAGE_FALSE'
export const U_PAYMENTMAKE_FROM_UPDATE = 'U_PAYMENTMAKE_FROM_UPDATE'
export const R_PAYMENTMAKE_FROM_UPDATE = 'R_PAYMENTMAKE_FROM_UPDATE'
export const PAYMENTMAKE_TO_UPDATE = 'PAYMENTMAKE_TO_UPDATE'
export const PAYMENTMAKE_INIT = 'PAYMENTMAKE_INIT'
export const PAYMENTMAKE_RESET = 'PAYMENTMAKE_RESET'
export const PAYMENTMAKE_IGNORE_NEXT_RESET = 'PAYMENTMAKE_IGNORE_NEXT_RESET'
export const PAYMENTMAKE_DETAILS_UPDATE = 'PAYMENTMAKE_DETAILS_UPDATE'
export const PAYMENTMAKE_NEWPAYEE_SHOW = 'PAYMENTMAKE_NEWPAYEE_SHOW'
export const PAYMENTMAKE_NEWPAYEE_HIDE = 'PAYMENTMAKE_NEWPAYEE_HIDE'
export const PAYMENTMAKE_NEWPAYEE_DETAILS_UPDATE = 'PAYMENTMAKE_NEWPAYEE_DETAILS_UPDATE'
export const PAYMENTMAKE_NEWPAYEE_DETAILS_CLEAR = 'PAYMENTMAKE_NEWPAYEE_DETAILS_CLEAR'
export const PAYMENTMAKE_RESET_SUCCESS = 'PAYMENTMAKE_RESET_SUCCESS'

export const PAYMENTS_FUTURE_INIT = 'PAYMENTS_FUTURE_INIT'

export const PAYMENTS_RECURRING_INIT = 'PAYMENTS_RECURRING_INIT'

export const ACCOUNT_SERVICES_INIT = 'ACCOUNT_SERVICES_INIT'
export const ACCOUNT_SERVICES_SELECT_ACCOUNT = 'ACCOUNT_SERVICES_SELECT_ACCOUNT'

export const PAYMENTS_BULK_INIT = 'PAYMENTS_BULK_INIT'
export const PAYMENTS_BULK_ITEM_INIT = 'PAYMENTS_BULK_ITEM_INIT'
export const PAYMENTS_BULK_LIST_UPDATE = 'PAYMENTS_BULK_LIST_UPDATE'

export const TWO_FACTOR_AUTH_NEXT_STEP = 'TWO_FACTOR_AUTH_NEXT_STEP'
export const TWO_FACTOR_AUTH_SET_REF = 'TWO_FACTOR_AUTH_UPDATE_REF'
export const TWO_FACTOR_AUTH_RESET = 'TWO_FACTOR_AUTH_RESET'
export const TWO_FACTOR_AUTH_GO_BACK = 'TWO_FACTOR_AUTH_GO_BACK'
export const TWO_FACTOR_AUTH_SET_SUCCESS_ACTION = 'TWO_FACTOR_AUTH_SET_SUCCESS_ACTION'
export const TWO_FACTOR_AUTH_SET_INITIAL_REQUEST = 'TWO_FACTOR_AUTH_SET_INITIAL_REQUEST'
export const TWO_FACTOR_AUTH_COMPLETE = 'TWO_FACTOR_AUTH_COMPLETE'

export const TRANSFERMONEY_FROM_CHANGE = 'TRANSFERMONEY_FROM_CHANGE'
export const TRANSFERMONEY_INIT = 'TRANSFERMONEY_INIT'

export const PAYEES_FETCH_REQUEST = 'PAYEES_FETCH_REQUEST'
export const PAYEES_FETCH_FAIL = 'PAYEES_FETCH_FAIL'
export const PAYEES_FILTER_ALPHABET = 'PAYEES_FILTER_ALPHABET'

export const MAIL_LIST_UPDATE = 'MAIL_LIST_UPDATE'
export const MAIL_LIST_FETCH_FAIL = 'MAIL_LIST_FETCH_FAIL'
export const MAIL_LIST_FETCH_REQUEST = 'MAIL_LIST_FETCH_REQUEST'
export const MAIL_DETAIL_FETCH_REQUEST = 'MAIL_DETAIL_FETCH_REQUEST'
export const MAIL_DETAIL_UPDATE = 'MAIL_DETAIL_UPDATE'
export const MAIL_BULLETINS_FETCH = 'MAIL_BULLETINS_FETCH'

export const LIST_UPDATE_ALL = 'LIST_UPDATE_ALL'
export const LIST_FETCH_START = 'LIST_FETCH_START'
export const LIST_FETCH_FAIL = 'LIST_FETCH_FAIL'
export const LIST_ITEM_UPDATE = 'LIST_ITEM_UPDATE'
export const LIST_MERGE_ITEMS = 'LIST_MERGE_ITEMS'
export const LIST_MERGE_ITEM_DETAILS = 'LIST_MERGE_ITEM_DETAILS'
export const LIST_CLEAR_ITEMS = 'LIST_CLEAR_ITEMS'
export const LIST_FETCH_STOP = 'LIST_FETCH_STOP'
export const LIST_ITEM_DELETE = 'LIST_ITEM_DELETE'

export const ACTIVITY_LOG_INIT = 'ACTIVITY_LOG_INIT'
export const ACTIVITY_LOG_TYPE_INIT = 'ACTIVITY_LOG_TYPE_INIT'
export const ACTIVITY_LOG_TYPE_UPDATE = 'ACTIVITY_LOG_SINGLE_UPDATE'
export const ACTIVITY_LOG_SINGLE_INIT = 'ACTIVITY_LOG_SINGLE_INIT'

export const ALERTS_INIT = 'ALERTS_INIT'
export const ALERTS_UPDATE = 'ALERTS_UPDATE'
export const ALERTS_SAVED = 'ALERTS_SAVED'

export const AUTHORISATIONS_FETCH_REQUEST = 'AUTHORISATIONS_FETCH_REQUEST'
export const AUTHORISATIONS_PROCESS_REQUEST = 'AUTHORISATIONS_PROCESS_REQUEST'
export const AUTHORISATIONS_PROCESS_SUCCESS = 'AUTHORISATIONS_PROCESS_SUCCESS'
export const AUTHORISATIONS_INIT = 'AUTHORISATIONS_INIT'

export const MARKETING_MODAL_DO_NOT_WISH_TO_SEE = 'MARKETING_MODAL_DO_NOT_WISH_TO_SEE'
export const MARKETING_MODAL_CLOSED = 'MARKETING_MODAL_CLOSED'
export const SHOW_PENDING_TRANSACTIONS = 'SHOW_PENDING_TRANSACTIONS'
export const HIDE_PENDING_TRANSACTIONS = 'HIDE_PENDING_TRANSACTIONS'
export const SHOW_AVAILABLE_BALANCE_INFO_MODAL = 'SHOW_AVAILABLE_BALANCE_INFO_MODAL'
export const HIDE_AVAILABLE_BALANCE_INFO_MODAL = 'HIDE_AVAILABLE_BALANCE_INFO_MODAL'
// ------------------------------------
// Request id constants
// Camel case
// always starts with "request"
// ------------------------------------

export const requestEntities = 'requestEntities'
export const requestAccounts = 'requestAccounts'
export const requestLastTransaction = 'requestLastTransaction'
export const requestTransactions = 'requestTransactions'
export const requestLoanTransactions = 'requestLoanTransactions'
export const requestTermDepositTransactions = 'requestTermDepositTransactions'
export const requestNonce = 'requestNonce'
export const requestNonceAtLogin = 'requestNonceAtLogin'
export const requestBulkId = 'requestBulkId'
export const requestPermissions = 'requestPermissions'
export const requestPartyRelations = 'requestPartyRelations'
export const requestLogin1 = 'requestLogin1'
export const requestLogin2 = 'requestLogin2'
export const requestLoginFlow = 'requestLoginFlow'
export const requestForceChangeCredential = 'requestForceChangeCredential'
export const requestAcceptTerms = 'requestAcceptTerms'
export const requestFirstTimeFlags = 'requestFirstTimeFlags'
export const requestUserTransactionLimit = 'requestUserTransactionLimit'
export const requestUserTransactionLimitSpent = 'requestUserTransactionLimitSpent'
export const requestGroupTransactionLimit = 'requestGroupTransactionLimit'
export const requestGroupTransactionLimitSpent = 'requestGroupTransactionLimitSpent'
export const requestLogout = 'requestLogout'
export const requestFirstTimeSkipStep = 'requestFirstTimeSkipStep'
export const requestFirstTimeComplete = 'requestFirstTimeComplete'
export const requestStatements = 'requestStatements'
export const requestStatementDownload = 'requestStatementDownload'
export const requestLoanDetails = 'requestLoanDetails'
export const requestLoanDetailsPrincipalbalance = 'requestLoanDetailsPrincipalbalance'
export const requestTermDepositDetails = 'requestTermDepositDetails'
export const requestTransferMapping = 'requestTransferMapping'
export const requestAccountDetails = 'requestAccountDetails'
export const requestMail = 'requestMail'
export const requestMailSend = 'requestMailSend'
export const requestMailDetail = 'requestMailDetail'
export const requestUpdateMailStatus = 'requestUpdateMailStatus'
export const requestBulletins = 'requestBulletins'
export const requestBulletinDetail = 'requestBulletinDetail'
export const requestUpdateBulletinsStatus = 'requestUpdateBulletinsStatus'
export const requestManageCredentials = 'requestManageCredentials'
export const requestAccountNameChange = 'requestAccountNameChange'
export const requestPaymentMapping = 'requestPaymentMapping'
export const requestPayeeGroup = 'requestPayeeGroup'
export const requestPayeeDetails = 'requestPayeeDetails'
export const requestPayees = 'requestPayees'
export const requestPayeeDelete = 'requestPayeeDelete'
export const requestPayeeNew = 'requestPayeeNew'
export const requestPayeeNewInit = 'requestPayeeNewInit'
export const requestPayeeNewInit2 = 'requestPayeeNewInit2'
export const requestPayeeNewInit3 = 'requestPayeeNewInit3'
export const requestTransferOrPayment = 'requestTransferOrPayment'
export const requestTransferOrPaymentGet = 'requestTransferOrPaymentGet'
export const requestTransferOrPaymentPatch = 'requestTransferOrPaymentPatch'
export const requestVerifySortCode = 'requestVerifySortCode'
export const requestPayeeCreateOrModify = 'requestPayeeCreateOrModify'
export const requestTwoFactorAuth = 'requestTwoFactorAuth'
export const requestFuturePayments = 'requestFuturePayments'
export const requestFuturePaymentsStop = 'requestFuturePaymentsStop'
export const requestFuturePaymentsStopPatch = 'requestFuturePaymentsStopPatch'
export const requestPaymentsRecurringMapping = 'requestPaymentsRecurringMapping'
export const requestStandingOrders = 'requestStandingOrders'
export const requestDirectDebitStop = 'requestDirectDebitStop'
export const requestDirectDebitStopPatch = 'requestDirectDebitStopPatch'
export const requestDirectDebit = 'requestDirectDebit'
export const requestStandingOrderStop = 'requestStandingOrderStop'
export const requestStandingOrderStopPatch = 'requestStandingOrderStopPatch'
export const requestStatementsMapping = 'requestStatementsMapping'
export const requestChequeBookMapping = 'requestChequeBookMapping'
export const reqChqStatMapp = 'reqChqStatMapp'
export const requestPayInBookMapping = 'requestPayInBookMapping'
export const requestActivityLogTypes = 'requestActivityLogTypes'
export const requestActivityLogType = 'requestActivityLogType'
export const requestPendingApprovals = 'requestPendingApprovals'
export const requestApprovalCount = 'requestApprovalCount'
export const requestActivityLogSingle = 'requestActivityLogSingle'
export const requestPaymentsBulkMapping = 'requestPaymentsBulkMapping'
export const requestPaymentsBulkSearch = 'requestPaymentsBulkSearch'
export const requestPaymentsBulkItem = 'requestPaymentsBulkItem'
export const requestPaymentsBulkErrorLog = 'requestPaymentsBulkErrorLog'
export const requestPaymentsBulkUpload = 'requestPaymentsBulkUpload'
export const requestAccountCustomerAddress = 'requestAccountCustomerAddress'
export const requestAccountServicesDeliverBook = 'requestAccountServicesDeliverBook'
export const requestPaymentsBulkDownloadFile = 'requestPaymentsBulkDownloadFile'
export const requestChequeStatus = 'requestChequeStatus'
export const requestAlertsMapping = 'requestAlertsMapping'
export const requestAuthorisationProcess = 'requestAuthorisationProcess'
export const requestAccountAlerts = 'requestAccountAlerts'
export const requestUserAlerts = 'requestUserAlerts'
export const requestSubscribedUserAlerts = 'requestSubscribedUserAlerts'
export const requestAlertsUpload = 'requestAlertsUpload'
export const requestAccountOrderChange = 'requestAccountOrderChange'
export const requestPublicKey = 'requestPublicKey'
export const requestSalt = 'requestSalt'

// call for token verification

export const requestAuthTokenVerification = 'requestAuthTokenVerification'
export const requestNewForceChange = 'requestNewForceChange'
export const requestPasswordPolicy = 'requestPasswordPolicy'
// end

export const SESSION_EVENT_TIMESTAMP = 'eventTimeStamp'
export const MAX_ALLOWED_TIME_FOR_NAVIGATING_AWAY = 30000 // in milliseconds
export const LOGOUT_MULTI_LOGIN_REQUEST = 'LOGOUT_MULTI_LOGIN_REQUEST'
export const tabOrScreenId = 'tabOrScreenId'
export const WAIT_BETWEEN_MULTI_LOGIN = 5000 // in milliseconds
export const AUTOLOGOUT_IDLE_TIME = 11 // in minutes

// payment cutoff modal

export const SHOW_PAYMENT_CUTOFF_MODAL = 'SHOW_PAYMENT_CUTOFF_MODAL'
export const HIDE_PAYMENT_CUTOFF_MODAL = 'HIDE_PAYMENT_CUTOFF_MODAL'
// end
